import React, { useState } from 'react';
import moment from 'moment';
import MuiMarkdown from 'mui-markdown';
import { Box, Tooltip, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import TranslateIcon from '@mui/icons-material/Translate';
import EditIcon from '@mui/icons-material/Edit';
import { greyboxApiActions } from '../../redux/api';
import Media from './Media';

const MessageBox = ({ message, previousMessage, threadId }) => {
  const { userId } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const { messageThread } = greyboxApiActions;
  const [deleteMessage] = messageThread.delete();
  const [updateMessage] = messageThread.update();
  const alignRight = userId === message.created_by.id;
  const time = moment().diff(message.created, 'days') > 0
    ? moment(message.created).format('MMM DD, YYYY')
    : moment(message.created).format('h:mm A');
  const toolTipText = `${message.created_by.first_name} ${message.created_by.last_name} ${time}`;
  const isNewInstance = !previousMessage || moment(message.created).diff(previousMessage.created, 'minutes') > 5;
  const isNewPerson = !previousMessage || previousMessage.created_by.id !== message.created_by.id;
  const isScheduled = message.scheduled_time
    ? moment(message.scheduled_time).isAfter(moment())
    : false;
  const [hover, setHover] = useState(false);
  const [translation, setTranslation] = useState('');
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message.message);

  const translate = async (text) => {
    const apiKey = '61568b414d3c4ecb9dbe4c4304753536';
    const baseUrl = 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0';
    const url = `${baseUrl}&to=${i18n.language}`;
    const headers = {
      'Ocp-Apim-Subscription-Key': apiKey,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const body = [{ text: text }];

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    const result = await response.json();
    setTranslation(result[0].translations[0].text);
  };

  const handleDeleteMessage = () => {
    deleteMessage(`${threadId}/message/${message.id}`);
    setDeleteDialogOpen(false);
  };

  const handleUpdateMessage = () => {
    updateMessage({ id: `${threadId}/message/${message.id}`, body: { message: editedMessage } });
    setEditMode(false);
  };

  const scheduledToolTipText = isScheduled && userId === message.created_by.id
    ? t('Scheduled for', { scheduled_time: moment(message.scheduled_time).format('h:mm A') })
    : toolTipText;

  const getBackgroundColor = () => {
    if (alignRight) {
      return isScheduled ? '#FFD700' : '#e5f5ff'; // gold for scheduled, blue for user
    }
    return '#f5f5f5'; // grey for others
  };

  if (isScheduled && userId !== message.created_by.id) {
    return null;
  }

  return (
    <>
      {(isNewPerson || isNewInstance) && (
        <Box display="flex" justifyContent={alignRight ? 'flex-end' : 'flex-start'} mb={0.5}>
          <Typography variant="caption" color="textSecondary">
            {`${message.created_by.first_name} ${message.created_by.last_name}`}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        mb={1}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        justifyContent={alignRight ? 'flex-end' : 'flex-start'}
      >
        {alignRight && hover && !isEditMode && (
          <Box display="flex" alignItems="center" mr={1}>
            {!translation && !message.media && (
              <IconButton
                size="small"
                sx={{ color: 'primary.main' }}
                onClick={() => translate(message.message)}
              >
                <TranslateIcon fontSize="small" />
              </IconButton>
            )}
            {!message.media && (
              <IconButton
                size="small"
                sx={{ color: 'primary.main' }}
                onClick={() => setEditMode(true)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton
              size="small"
              sx={{ color: 'primary.main' }}
              onClick={() => setDeleteDialogOpen(true)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        <Box sx={{ position: 'relative', maxWidth: '80%' }}>
          <Tooltip title={scheduledToolTipText} placement={alignRight ? 'left' : 'right'}>
            <Box
              sx={{
                backgroundColor: getBackgroundColor(),
                display: 'flex',
                borderRadius: 2,
                padding: 1,
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                flexDirection: 'column',
                boxShadow: 1,
              }}
            >
              {message.media ? (
                <Media media={message.media} />
              ) : (
                <>
                  {isEditMode ? (
                    <Box>
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        value={editedMessage}
                        onChange={(e) => setEditedMessage(e.target.value)}
                        sx={{
                          backgroundColor: '#ffffff',
                          borderRadius: '4px',
                          mb: 1,
                        }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          size="small"
                          sx={{ color: 'primary.main' }}
                          onClick={handleUpdateMessage}
                        >
                          <CheckIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ color: 'primary.main' }}
                          onClick={() => setEditMode(false)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <MuiMarkdown
                      overrides={{
                        a: {
                          component: 'a',
                          props: {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                          },
                        },
                      }}
                    >
                      {message.message}
                    </MuiMarkdown>
                  )}
                </>
              )}
              {translation && (
                <>
                  <Typography variant="caption" color="textSecondary" mt={1}>
                    {`(${t('Auto-translated')})`}
                  </Typography>
                  <Box mt={0.5}>
                    <MuiMarkdown
                      overrides={{
                        a: {
                          component: 'a',
                          props: {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                          },
                        },
                      }}
                    >
                      {translation}
                    </MuiMarkdown>
                  </Box>
                </>
              )}
            </Box>
          </Tooltip>
        </Box>
        {!alignRight && hover && (
          <Box display="flex" alignItems="center" ml={1}>
            {!translation && !message.media && (
              <IconButton
                size="small"
                sx={{ color: 'primary.main' }}
                onClick={() => translate(message.message)}
              >
                <TranslateIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      {isNewInstance && (
        <Typography variant="caption" color="textSecondary" textAlign="center" mt={-1}>
          {time}
        </Typography>
      )}

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{t('Delete Message')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete this message? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDeleteMessage} color="primary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MessageBox;
