import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { suggested_style } from './suggestedMedications_style';
import pharma_style from '../pharm_wrapper_style.js';
import Contraindications from './contraindications';
import { greyboxApiActions } from '../../../../redux/api';
import { MedicationList } from './medicationList';
import { Consultation, DisplayInformation, SuggestedNone } from './medicationsWarning';
import { usePatientWebSocket } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  tile: {
    width: '232px',
    borderRadius: '5px',
    margin: '10px 20px 0 0px',
    backgroundColor: theme.palette.warning.main,
  },
  drug_tileHeader: {
    width: '100%',
    height: '76px',
    padding: '7px 5px 0px 5px',
    boxSizing: 'border-box',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    background: 'rgba(255,255,255,0.65)',
  },
  taken: {
    backgroundColor: theme.palette.success.light,
  },
  drugclass_name: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    color: '#454545',
    marginLeft: '7px',
  },
  or_medication: {
    backgroundColor: '#e0f5f5',
    borderRadius: 5,
    padding: 10,
    width: 232,
    marginTop: 10,
  },
  smallLine: {
    width: '8px',
    height: '2px',
    backgroundColor: theme.palette.divider,
    marginRight: '5px',
  },
  classLine: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.divider,
    marginLeft: '5px',
  },
}));

const contraindicationValidator = (contraindications = []) => (
  contraindications.some((item) => item.displayWarning)
);

const classCheck = (suggestion, activeCodes) => (
  suggestion.medications.map((el) => (
    activeCodes.some((item) => el.active_ingredient_codes.includes(item))))
);

const messageMapping = {
  'consult-endocrinologist': <Consultation />,
  'suggest-do-nothing': <SuggestedNone />,
  information: <DisplayInformation />,

};

const SuggestedMedications = (props) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const {
    uuid, acc_type, role_type_code,
  } = props;
  const { medication, treatmentPlan } = greyboxApiActions;
  const { data = [], isFetching, refetch } = treatmentPlan.list({
    patient: uuid, latest: true, clinic: clinic.id,
  });
  const medicationSelector = medication.list({ account: uuid, status: 'active,intended' });
  let activeCodes = [];

  if (!medicationSelector.isFetching) {
    activeCodes = medicationSelector.data
      .map((el) => el.medication.active_ingredient.map((el) => el.active_ingredient_code))
      .flat();
  }

  const classes = useStyles();

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    refetch();
    medicationSelector.refetch();
  }, []);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Decision_Tree_History') {
        refetch();
      }
    }
  }, [lastJsonMessage]);

  if (isFetching) {
    return null;
  }

  return (
    data.length > 0 && (
      <>
        <div style={{ transition: 'all 2s ease' }} data-cy="SuggestedMedications">
          {/* <div style={pharma_style.separator} /> */}
          <Typography style={pharma_style.header}>{t('Suggested Treatment Plan')}</Typography>
        </div>
        {data.map((el, idx) => {
          const decision = el.decision_tree_output;

          return (
            <div key={`wrapper-${idx}`}>
              <SectionTitle decision={decision} />
              <div style={suggested_style.wrapperMedTiles} key={idx}>
                {decision.data ? (
                  decision.data.map((suggestion, index) => {
                    if (Array.isArray(suggestion)) {
                      return (
                        <div className={classes.or_medication} key={`med-${index}`}>
                          <MedicationTile
                            data={suggestion[0]}
                            indexBox={index}
                            uuid={uuid}
                            acc_type={acc_type}
                            role_type_code={role_type_code}
                          />
                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <Typography>
                              ---
                              {t('OR')}
                              ---
                            </Typography>
                          </div>
                          <MedicationTile
                            indexBox={index}
                            data={suggestion[1]}
                            uuid={uuid}
                            acc_type={acc_type}
                          />
                        </div>
                      );
                    }
                    return (
                      <MedicationTile
                        key={`med-${index}`}
                        indexBox={index}
                        uuid={uuid}
                        acc_type={acc_type}
                        data={suggestion}
                        role_type_code={role_type_code}
                        activeCodes={activeCodes}
                      />
                    );
                  })
                ) : (
                  <div style={{ marginTop: '10px' }}>
                    {messageMapping[decision.output_code]}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </>
    )
  );
};

function MedicationTile(props) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const {
    acc_type,
    uuid,
    data,
    indexBox,
    role_type_code,
    activeCodes = [],
  } = props;
  const activeClass = classCheck(data, activeCodes);

  return (
    <div className={`${classes.tile} ${((activeClass.some((el) => el === true) ? classes.taken : ''))}`}>
      <div className={classes.drug_tileHeader}>
        <Typography className={classes.drugclass_name}>
          {data.medication_class[i18n.resolvedLanguage]}
        </Typography>
        <Contraindications
          drugclass={data.medication_class}
          contraindications={data.contraindication}
          indexBox={indexBox}
          onCards
          warning={contraindicationValidator(data.contraindication)}
        />
      </div>
      <div>
        <MedicationList
          medication_list={data.medications}
          uuid={uuid}
          acc_type={acc_type}
          role_type_code={role_type_code}
          activeCodes={activeCodes}
        />
      </div>
    </div>
  );
}

const SectionTitle = ({ decision }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sectionTextMapping = {
    HF: {
      title: `${t('Heart Failure')} (${t('under reserve, in development')})`,
      subtitle: `${t('According to algorithms based on heart failure and diabetes guidelines')}.`,
    },
    HF_diabetes: {
      title: `${t('Diabetes with Heart Failure')} (${t('under reserve, in development')})`,
      subtitle: `${t('According to algorithms based on heart failure and diabetes guidelines')}.`,
    },
    MH: {
      title: t('Mental Health'),
      subtitle: '',
    },
  };

  return (
    <>
      <div style={suggested_style.classWrapper}>
        <span className={classes.smallLine} />
        <Typography style={{ whiteSpace: 'nowrap' }}>
          <strong>
            {sectionTextMapping[decision.guideline_suggestions_category].title}
          </strong>
        </Typography>
        <span className={classes.classLine} />
      </div>
      <Typography style={pharma_style.subTitle}>
        {sectionTextMapping[decision.guideline_suggestions_category].subtitle}
      </Typography>
    </>
  );
};

export default SuggestedMedications;
