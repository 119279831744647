import { useTabContext } from '@mui/lab';
import { Box, FormControl, FormLabel } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import { useTranslation } from 'react-i18next';

function MarkdownPreview({ preview }) {
  const {t} = useTranslation();
  return (
    <FormControl variant="outlined" fullWidth sx={{ my: 2 }}>
      <FormLabel
        sx={{
          position: 'absolute',
          top: '-8px',
          left: '12px',
          backgroundColor: 'white',
          px: 1,
          fontSize: '0.75rem',
          color: 'text.secondary',
        }}
      >
        {t('Preview')}
      </FormLabel>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '16px',
          fontFamily: 'monospace',
          minHeight: '100px',
          whiteSpace: 'pre-wrap',
          overflow: 'auto',
        }}
      >
        <MuiMarkdown>{preview}</MuiMarkdown>
      </Box>
    </FormControl>
  );
}

export default MarkdownPreview;