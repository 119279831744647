import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import 'moment/locale/fr';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';
import { useDebounce } from 'use-debounce';


const SearchMedicationField = ({ setSelected, initialSearch = '' }) => {
  const { t } = useTranslation();
  const { medications } = greyboxApiActions;
  const [query, setQuery] = useState(initialSearch);
  const [debouncedValue] = useDebounce(query, 300);

  // Create a ref to the TextField
  const inputRef = useRef(null);

  // Focus the input when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const { data = {}, isFetching } = medications.list(
    {
      page_size: 10,
      search: debouncedValue,
    },
    {
      skip: !debouncedValue,
    },
  );

  const options = data.results || [];

  const handleInputChange = (_, newInputValue) => {
    setQuery(newInputValue);
  };

  const handleSelect = (item) => {
    if (!item || typeof item === 'string') {
      return;
    }

    setSelected(item);
    setQuery('');
  };

  return (
    <Autocomplete
      options={options}
      open={!!debouncedValue}
      filterOptions={(x) => x}
      freeSolo
      clearOnBlur
      value={query}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => (
        typeof option === 'string' ? option : option.brand_name
      )}
      openText={t('Open')}
      clearText={t('Clear')}
      noOptionsText=""
      onChange={(event, value) => handleSelect(value)}
      loadingText={`${t('Loading')}...`}
      loading={isFetching}
      renderOption={(props, option) => (
        <MenuItem
          key={option.uuid}
          {...props}
        >
          <div>
            <div>
              <Typography>{option.brand_name}</Typography>
            </div>
            <Box display="flex" flexWrap="wrap">
              {option.active_ingredient.map((ingredient) => (
                <Typography key={`${option.uuid}-${ingredient.uuid}`} variant="caption">
                  {ingredient.ingredient}
                  {' '}
                  {ingredient.strength}
                  {' '}
                  {ingredient.strength_unit}
                  {' '}
                </Typography>
              ))}
            </Box>
          </div>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('Search medication')}
          inputRef={inputRef}  // Assign ref to TextField
        />
      )}
    />
  );
};

export default SearchMedicationField;
