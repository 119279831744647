import React from 'react';
import {
  Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../redux/api';
import ChatBox from './ChatBox';

/**
 * Used to display messages from clinic to patient. It can toggle if the patient can
 * answer or not. The previous message (if any) will load when the user scrolls to the top.
 */
const DialogChatBox = (props) => {
  const { open, handleClose } = props;
  const { messageThread } = greyboxApiActions;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const patient = useSelector((state) => state.patient);
  const { isLoading, data, isError } = messageThread.list({
    patient: patient.accountId,
    clinic: clinic.id,
  });

  if (isLoading) {
    return null;
  }

  if (!data || data.length === 0 || isError) {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{t('Error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('There was an error loading the chat. Please try again later.')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <ChatBox threadId={data[0].id} handleClose={handleClose} open={open} isDialog />
    </Dialog>
  );
};

export default DialogChatBox;
