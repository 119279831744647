import moment from 'moment';
import i18n from '../../i18n';

const colorMap = {
  completed: '#D8D8D8',
  active: '#4CAF50',
  intended: '#FFC107',
};

export const parseMedForTimeLine = (medications) => {
  const medForTimeLine = [];
  const completed = [];
  const active = [];
  const intended = [];
  const colors = [];

  medications.forEach((med) => {
    const { start_date, end_date, medication: { brand_name } } = med;
    const startDate = new Date(start_date).getTime();
    const endDate = end_date ? new Date(end_date).getTime() : new Date().getTime();

    switch (med.status) {
      case 'completed':
        completed.push({
          x: brand_name,
          y: [startDate, endDate],
          fillColor: colorMap.completed,
        });
        break;
      case 'active':
        active.push({
          x: brand_name,
          y: [startDate, endDate],
          fillColor: colorMap.active,
        });
        break;
      case 'intended':
        intended.push({
          x: brand_name,
          y: [startDate, endDate],
          fillColor: colorMap.intended,
        });
        break;
      default:
        break;
    }
  });

  if (intended.length) {
    medForTimeLine.push({
      name: i18n.t('Intended'),
      data: intended,
    });
    colors.push(colorMap.intended);
  }

  if (active.length) {
    medForTimeLine.push({
      name: i18n.t('Active'),
      data: active,
    });
    colors.push(colorMap.active);
  }

  if (completed.length) {
    medForTimeLine.push({
      name: i18n.t('Completed'),
      data: completed,
    });
    colors.push(colorMap.completed);
  }

  return { medForTimeLine, colors };
};

export const parseMin = (data = [], duration = 'day') => (
  data.map((item) => ({
    x: moment(item.bucket).startOf(duration),
    y: item.min,
  }))
);

export const parseMax = (data = [], duration = 'day') => (
  data.map((item) => ({
    x: moment(item.bucket).startOf(duration),
    y: item.max,
  }))
);

export const parseCount = (data = [], duration = 'day') => (
  data.map((item) => ({
    x: moment(item.bucket).startOf(duration),
    y: item.count,
    fillColor: '#83dde7',
  }))
);

export const parseAverage = (data = [], duration = 'day') => {
  const parsedAverages = {};
  data.forEach((item) => {
    if (!(item.short_code in parsedAverages)) {
      parsedAverages[item.short_code] = [];
    }
    parsedAverages[item.short_code].push({
      x: moment(item.bucket).startOf(duration),
      y: item.avg,
    });
  });
  return parsedAverages;
};

export const parseVitalsData = (data = [], vitalName) => {
  if (vitalName === 'steps') {
    return data.map((item) => ({
      x: moment(item.endTime).format('MM-DD-YYYY HH:mm'),
      y: item.steps,
      unit: 'steps',
    }));
  }

  return data.map((item) => ({
    x: moment(item.time).format('MM-DD-YYYY HH:mm'),
    y: item[vitalName],
    unit: item.unit,
  }));
};

export const parseObservationData = (data = []) => {
  const series = {};

  data.forEach((item) => {
    if (!series[item.short_code]) {
      series[item.short_code] = [];
    }

    const { effectiveDateTime, valueQuantity, valueInteger } = item;

    let yValue;
    let unit = '';

    if (valueQuantity) {
      yValue = valueQuantity.value;
      unit = valueQuantity.unit || '';
    } else if (valueInteger !== undefined) {
      yValue = valueInteger;
    }

    if (yValue !== undefined) {
      series[item.short_code].push({
        x: moment(effectiveDateTime).format('MM-DD-YYYY HH:mm'),
        y: yValue,
        unit: unit,
      });
    }
  });

  return series;
};


export const parseThresholds = (selector, date, shortCode, measures) => {
  const series = [];

  if (!selector || !selector.data) {
    return [];
  }

  const startDate = moment(date).format();
  const currentSeries = {};

  if (shortCode === 'BP') {
    if (selector.data.length === 0) {
      return [];
    }
    selector.data.forEach((item) => {
      currentSeries.tensionHigh = {};
      currentSeries.tensionLow = {};
      const isBefore = moment(item.created_date).isBefore(moment(date));
      const { end_date } = item;

      if (item.upperRange_5) {
        if (!currentSeries.tensionHigh.upper) {
          currentSeries.tensionHigh.upper = [];
        }

        currentSeries.tensionHigh.upper.push(
          {
            x: isBefore ? startDate : moment(item.created_date).format(),
            y: item.upperRange_5,
          },
          {
            x: end_date ? moment(end_date).format() : moment().format(),
            y: item.upperRange_5,
          },
        );
      }

      if (item.lowerRange_5) {
        if (!currentSeries.tensionHigh.lower) {
          currentSeries.tensionHigh.lower = [];
        }

        currentSeries.tensionHigh.lower.push(
          {
            x: isBefore ? startDate : moment(item.created_date).format(),
            y: item.lowerRange_5,
          },
          {
            x: end_date ? moment(end_date).format() : moment().format(),
            y: item.lowerRange_5,
          },
        );
      }

      if (item.upperRange_1) {
        if (!currentSeries.tensionLow.upper) {
          currentSeries.tensionLow.upper = [];
        }

        currentSeries.tensionLow.upper.push(
          {
            x: isBefore ? startDate : moment(item.created_date).format(),
            y: item.upperRange_1,
          },
          {
            x: end_date ? moment(end_date).format() : moment().format(),
            y: item.upperRange_1,
          },
        );
      }

      if (item.lowerRange_1) {
        if (!currentSeries.tensionLow.lower) {
          currentSeries.tensionLow.lower = [];
        }

        currentSeries.tensionLow.lower.push(
          {
            x: isBefore ? startDate : moment(item.created_date).format(),
            y: item.lowerRange_1,
          },
          {
            x: end_date ? moment(end_date).format() : moment().format(),
            y: item.lowerRange_1,
          },
        );
      }
    });
  } else {
    selector.data.forEach((item) => {
      const isBefore = moment(item.created_date).isBefore(moment(date));
      const { end_date } = item;

      if (!currentSeries[item.function]) {
        currentSeries[item.function] = {};
      }

      const addRange = (rangeKey, rangeValue) => {
        if (rangeValue) {
          if (!currentSeries[item.function][rangeKey]) {
            currentSeries[item.function][rangeKey] = [];
          }
          currentSeries[item.function][rangeKey].push(
            {
              x: isBefore ? startDate : moment(item.created_date).format(),
              y: rangeValue,
            },
            {
              x: end_date ? moment(end_date).format() : moment().format(),
              y: rangeValue,
            },
          );
        }
      };

      addRange('lower', item.lowerRange_1);
      addRange('upper', item.upperRange_1);
      addRange('lower_mod', item.lowerRange_2);
      addRange('upper_mod', item.upperRange_2);
      addRange('lower_low', item.lowerRange_3);
      addRange('upper_low', item.upperRange_3);
    });

  }


  Object.keys(currentSeries).forEach((key) => {

    const addSeries = (name, rangeType, seriesNameKey, systolic, diastolic) => {
      if (currentSeries[key][rangeType]) {
        const sortedRange = currentSeries[key][rangeType].sort((a, b) => moment(a.x).diff(moment(b.x)));
        series.push({
          name: `${seriesNameKey} ${name} ${systolic} ${diastolic}`,
          type: 'line',
          data: sortedRange,
        });
      }
    };

    const name = measures.length > 1 ? measures.find((measure) => measure.threshold_code === key).name : '';
    const systolic = key === 'tensionHigh' ? i18n.t('Systolic') : '';
    const diastolic = key === 'tensionLow' ? i18n.t('Diastolic') : '';

    addSeries(name, 'lower', i18n.t('Lower threshold'), systolic, diastolic);
    addSeries(name, 'upper', i18n.t('Upper threshold'), systolic, diastolic);
    addSeries(name, 'lower_mod', i18n.t('Moderate lower threshold'), systolic, diastolic);
    addSeries(name, 'upper_mod', i18n.t('Moderate upper threshold'), systolic, diastolic);
    addSeries(name, 'lower_low', i18n.t('Low lower threshold'), systolic, diastolic);
    addSeries(name, 'upper_low', i18n.t('Low upper threshold'), systolic, diastolic);
  });

  return series;
};
