/**
 * Append to the following object with the desired name of the slice/reducer
 * and the path and CRUD actions.
 */
const apiEndpoints = [
  {
    name: 'invitation',
    path: 'greybox-api/invitation_tracking/',
    actions: ['LIST', 'PATCH', 'POST'],
  },
  {
    name: 'medication',
    path: 'greybox-api/medication_history/',
    actions: ['LIST', 'PATCH', 'POST'],
  },
  {
    name: 'convertDosage',
    path: 'greybox-api/convert-dosage/',
    actions: ['POST'],
  },
  {
    name: 'titrationStatus',
    path: 'greybox-api/titration_status/',
    actions: ['LIST'],
  },
  {
    name: 'questionnaireAnswers',
    path: 'greybox-api/questionnaire-answer/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'questionnaireToken',
    path: 'greybox-api/questionnaires-token/',
    actions: ['LIST', 'POST', 'PATCH'],
  },
  {
    name: 'questionnaireManagementClinic',
    path: 'greybox-api/questionnaire-clinic-management/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'questionManagement',
    path: 'greybox-api/question-management/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'company',
    path: 'greybox-api/company/',
    actions: ['LIST', 'PATCH'],
  },
  {
    name: 'account',
    path: 'greybox-api/account/',
    actions: ['LIST', 'PATCH', 'POST', 'DELETE', 'PUT'],
    tags: ['patientDashboard'],
  },
  {
    name: 'patientDashboard',
    path: 'greybox-api/patient-dashboard/',
    actions: ['LIST'],
  },
  {
    name: 'medicalActType',
    path: 'greybox-api/medical_act_type/',
    actions: ['LIST'],
  },
  {
    name: 'medicalMeasurementConfig',
    path: 'greybox-api/medical-measurement-config/',
    actions: ['LIST'],
  },
  {
    name: 'disclaimer',
    path: 'greybox-api/disclaimer/',
    actions: ['LIST'],
  },
  {
    name: 'patientReport',
    path: 'greybox-api/patient-report/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'patientReportClinic',
    path: 'greybox-api/patient-report-clinic/',
    actions: ['LIST'],
  },
  {
    name: 'treatmentPlan',
    path: 'greybox-api/decision_tree_history/',
    actions: ['LIST'],
  },
  {
    name: 'messageThread',
    path: 'greybox-api/message-thread/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'educationSection',
    path: 'greybox-api/education_library/sections/',
    actions: ['LIST',],
  },
  {
    name: 'educationArticle',
    path: 'greybox-api/education_library/articles/',
    actions: ['LIST'],
  },
  {
    name: 'educationSectionManagementClinic',
    path: 'greybox-api/education_library_management/sections-clinic/',
    actions: ['LIST', 'PATCH', 'PUT','DELETE'],
    tags: ['educationSection'],
  },
  {
    name: 'educationSectionManagement',
    path: 'greybox-api/education_library_management/sections/',
    actions: ['LIST', 'POST', 'PATCH', 'PUT', 'DELETE'],
    tags: ['educationSection'],
  },
  {
    name: 'educationArticleManagement',
    path: 'greybox-api/education_library_management/articles/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
    tags: ['educationArticle'],
  },
  {
    name: 'clinicalImpression',
    path: 'fhir/ClinicalImpression',
    actions: ['POST', 'PATCH', 'LIST'],
  },
  {
    name: 'allergyIntolerance',
    path: 'fhir/AllergyIntolerance',
    actions: ['LIST', 'PATCH'],
  },
  {
    name: 'questionnaire',
    path: 'greybox-api/questionnaire/',
    actions: ['LIST'],
  },
  {
    name: 'calendar',
    path: 'greybox-api/calendar/',
    actions: ['LIST', 'POST'],
  },
  {
    name: 'calendarEvent',
    path: 'greybox-api/calendar-event/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'labResultsConfig',
    path: 'greybox-api/lab_results_config/',
    actions: ['LIST'],
  },
  {
    name: 'labResults',
    path: 'greybox-api/lab_results/',
    actions: ['LIST', 'POST', 'PATCH'],
  },
  {
    name: 'clinicalDataConfig',
    path: 'greybox-api/clinical_data_config/',
    actions: ['LIST'],
  },
  {
    name: 'clinicalData',
    path: 'greybox-api/clinical_data/',
    actions: ['LIST', 'POST'],
  },
  {
    name: 'weight',
    path: 'greybox-api/weight/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'bloodPressure',
    path: 'greybox-api/bloodPressure/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'heartRate',
    path: 'greybox-api/heartRate/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'bloodGlucose',
    path: 'greybox-api/bloodGlucose/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'observation',
    path: 'greybox-api/observation/',
    actions: ['LIST', 'POST', 'PATCH'],
  },
  {
    name: 'steps',
    path: 'greybox-api/cardio/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'alert',
    path: 'greybox-api/alert/',
    actions: ['LIST', 'PATCH'],
    tags: ['medicalAct'],
  },
  {
    name: 'reminder',
    path: 'greybox-api/treatment_plan_reminder/',
    actions: ['LIST'],
  },
  {
    name: 'threshold',
    path: 'greybox-api/threshold/',
    actions: ['LIST', 'PATCH', 'POST'],
  },
  {
    name: 'medications',
    path: 'greybox-api/medications',
    actions: ['LIST', 'PATCH', 'POST'],
  },
  {
    name: 'media',
    path: 'greybox-api/medications',
    actions: ['LIST', 'POST'],
  },
  {
    name: 'posology',
    path: 'greybox-api/posology_freq/',
    actions: ['LIST'],
  },
  {
    name: 'user',
    path: 'greybox-api/user/',
    actions: ['LIST', 'PATCH'],
  },
  {
    name: 'tokenValidation',
    path: 'greybox-api/auth/token/',
    actions: ['POST'],
  },
  {
    name: 'emailValidation',
    path: 'greybox-api/auth/email/',
    actions: ['POST'],
  },
  {
    name: 'patientRegistration',
    path: 'greybox-api/patient-registration/',
    actions: ['POST'],
  },
  {
    name: 'diagnosis',
    path: 'greybox-api/diagnosis/',
    actions: ['LIST', 'POST'],
  },
  {
    name: 'diagnosisCategory',
    path: 'greybox-api/diagnosis_category/',
    actions: ['LIST'],
  },
  {
    name: 'medicalAct',
    path: 'greybox-api/medical_acts/',
    actions: ['LIST', 'POST'],
  },
  {
    name: 'careTeam',
    path: 'fhir/CareTeam',
    actions: ['LIST', 'POST', 'DELETE', 'PATCH', 'PUT'],
  },
  {
    name: 'permissions',
    path: 'greybox-api/permissions/user',
    actions: ['LIST'],
  },
  {
    name: 'roles',
    path: 'greybox-api/role/',
    actions: ['LIST'],
  },
  {
    name: 'observationSummary',
    path: 'greybox-api/observation-summary/',
    actions: ['LIST'],
  },
  {
    name: 'pharmacy',
    path: 'greybox-api/pharmacy/',
    actions: ['LIST', 'POST', 'PUT', 'PATCH', 'DELETE'],
  },
  {
    name: 'media',
    path: 'greybox-api/media/',
    actions: ['LIST', 'POST', 'DELETE'],
  },
  {
    name: 'informationMessage',
    path: 'greybox-api/information-message/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'device',
    path: 'fhir/Device',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'label',
    path: 'greybox-api/label/',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'documentReference',
    path: 'fhir/DocumentReference',
    actions: ['LIST', 'POST', 'PATCH', 'DELETE'],
  },
  {
    name: 'workflow',
    path: 'greybox-api/workflow/',
    actions: ['LIST'],
  },
];

// eslint-disable-next-line import/prefer-default-export
export { apiEndpoints };
