import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isInFuture, isToday } from '../../../../helper-functions';
import { greyboxApiActions } from '../../../../redux/api';
import MedicationTable from './MedicationTable';
import { Box, Typography } from '@mui/material';

const parseIngredients = (arrayItem, element) => {
  const obj = {};

  obj.strength = `${parseFloat(arrayItem.strength)} ${arrayItem.strength_unit.toLowerCase()}`;
  obj.strengthFr = `${parseFloat(arrayItem.strength)} ${arrayItem.strength_unit_fr.toLowerCase()}`;
  obj.unit = arrayItem.strength_unit.toLowerCase();
  obj.dosageUnit = arrayItem.dosage_unit.toLowerCase();
  obj.dosageUnitFr = arrayItem.dosage_unit_fr.toLowerCase();
  obj.drugUuid = arrayItem.uuid;
  obj.molecule = arrayItem.ingredient.toLowerCase();
  obj.moleculeFr =
    arrayItem.ingredient_fr === 'nan' ? arrayItem.ingredient.toLowerCase() : arrayItem.ingredient_fr.toLowerCase();
  // Pushing stuff up one level.
  // If null posology, we return N.A.; else we concatenate string when unit and dosage unit is not 'nan'
  element.unit = element.daily !== null
    ? `${arrayItem.strength_unit} ${arrayItem.dosage_unit === 'nan' ? '' : `/ ${arrayItem.dosage_unit}`}`.toLowerCase()
    : '---';

  element.unitFr = element.daily !== null
    ? `${arrayItem.strength_unit_fr} ${
        arrayItem.dosage_unit_fr === 'nan' ? '' : `/ ${arrayItem.dosage_unit_fr}`
      }`.toLowerCase()
    : '---';
  element.ingredient.push(obj);
};

const medicationParsing = (item, titrationStat = [], suggestedMedications = []) => {
  const aiCodeArr = item.medication.active_ingredient.map((el) => el.active_ingredient_code);
  const suggestedCodes = suggestedMedications.map((el) => el.list).flat();
  // Passing the cautions obj {class, contraindications} to the row.
  const asTitrationCaution =
    suggestedMedications.length > 0 &&
    aiCodeArr.length > 0 &&
    suggestedMedications.filter((el) => aiCodeArr.some((r) => el.list.includes(r)));

  // Returns if a medication is taken and active - intended
  const isSuggested =
    suggestedCodes.length > 0 && aiCodeArr.length > 0 && suggestedCodes.filter((el) => aiCodeArr.includes(el));

  const period = item.titration ? moment(item.titration.next_dose_date).diff(moment(Date.now()), 'days') : null;
  const rx = {
    uuid: item.uuid,
    name: item.medication.brand_name,
    medUuid: item.medication.uuid,
    daily: '',
    target: !!item.titration && item.titration.target_dose,
    nextDose: !!item.titration && item.titration.next_dose,
    nextDoseDate: !!item.titration && item.titration.next_dose_date,
    nextDoseTime: period ? parseInt(period, 10) : null,
    statusName: !!item.titration && titrationStat[item.titration.status]?.name,
    statusColor: !!item.titration && titrationStat[item.titration.status]?.color_code.bright,
    posology: '',
    startDate: item.start_date,
    endDate: item.end_date ? item.end_date : '---',
    lastActiveDay: isToday(item.end_date),
    isIntended: isInFuture(item.start_date),
    max_tolerated_dosage: item.max_tolerated_dosage,
    dosage: item.dosage_fhir[0],
    unit: '',
    unitFr: '',
    isNotSuggested: isSuggested ? !isSuggested.length > 0 : true,
    aiCode: item.medication.active_ingredient.map((el) => el.active_ingredient_code),
    cautions: asTitrationCaution && asTitrationCaution[0],
    ingredient: [],
  };

  item.medication.active_ingredient.forEach((med) => {
    parseIngredients(med, rx);
  });
  return rx;
};

const CurrentMedicationsWrapper = (props) => {
  const { titration_status_choices } = props;
  const { uuid } = useParams();
  const { clinic } = useSelector((state) => state.clinic);
  const [isHistory, setIsHistory] = useState(false);
  const [selected, setSelected] = useState([]);
  const { medication, treatmentPlan } = greyboxApiActions;
  const { t } = useTranslation();
  const treatmentPlanSelector = treatmentPlan.list({
    patient: uuid,
    latest: true,
    clinic: clinic.id,
  });
  const { data, isFetching } = medication.list({
    account: uuid,
    status: isHistory ? 'completed' : 'active,intended',
  });

  const suggestedMedications = useMemo(() => {
    if (!treatmentPlanSelector.isFetching) {
      const decisions = treatmentPlanSelector.data
        .map((item) => {
          const output = item.decision_tree_output;
          return output.output_code === 'suggest-medications' ? output.data : null;
        })
        .flat();
      const parsedMedications = [];
      decisions.forEach((item) => {
        if (Array.isArray(item)) {
          item.forEach((el) => {
            parsedMedications.push({
              class: el.medication_class.en,
              contraindications: el.contraindication,
              list: el.medications.map((med) => med.active_ingredient_codes).flat(),
            });
          });
        } else if (item) {
          parsedMedications.push({
            class: item.medication_class.en,
            contraindications: item.contraindication,
            list: item.medications.map((med) => med.active_ingredient_codes).flat(),
          });
        }
      });

      return parsedMedications;
    }
    return [];
  }, [treatmentPlanSelector.data]);

  const medications = useMemo(() => {
    if (!isFetching && data) {
      return data.map((item) =>
        medicationParsing(item, titration_status_choices, suggestedMedications)
      );
    }
    return [];
  }, [data, titration_status_choices, suggestedMedications]);

  return (
    <Box
      sx={{
        fontFamily: 'Roboto',
        marginBottom: '20px',
        fontSize: '14px',
        marginLeft: '0px',
        filter: isHistory ? 'saturate(0.7)' : 'saturate(1)',
        transition: 'all 400ms ease',
      }}
      data-cy="medWrapper"
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Roboto',
          fontSize: '20px',
          fontWeight: 500,
          textAlign: 'left',
          color: '#454545',
        }}
        data-cy="wrapperTitle"
      >
        {t('Medication Table')}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          width: '100%',
          color: '#707070',
          fontFamily: 'Roboto',
          fontSize: '14px',
          marginTop: '8px',
        }}
        data-cy="wrapperSubtitle1"
      >
        {`${t('Only drugs added with TakeCare are displayed')}. ${t(
          "The patient's pharmacological profile may be incomplete"
        )}.`}
      </Typography>
      <Box
        sx={{
          position: 'relative',
          height: '40px',
          background: isHistory ? '#7caeb6' : '#09bbd0',
          borderTopLeftRadius: '3px',
          borderTopRightRadius: '3px',
          transition: 'background 400ms ease',
          mt: 2,
        }}
        data-cy="tabBar"
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '15px',
            width: '80px',
            height: '22px',
            background: isHistory ? '#09bbd0' : '#ffffff',
            border: '1px solid',
            borderColor: isHistory ? 'rgba(255,255,255,0.2)' : 'white',
            borderBottom: 'none',
            color: isHistory ? '#ffffff' : '#747474',
            fontWeight: 500,
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            textAlign: 'center',
            paddingTop: '8px',
            cursor: 'pointer',
            transition: 'background 200ms ease',
          }}
          data-cy="tabActive"
          role="button"
          tabIndex={0}
          onClick={() => {
            setSelected([]);
            setIsHistory(false);
          }}
        >
          {t('Current')}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '108px',
            width: '85px',
            height: '24px',
            background: isHistory ? '#ffffff' : 'rgba(0,0,0,0.1)',
            color: isHistory ? '#747474' : '#ffffff',
            fontWeight: 500,
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            textAlign: 'center',
            paddingTop: '8px',
            cursor: 'pointer',
            transition: 'background 200ms ease',
          }}
          data-cy="history"
          role="button"
          tabIndex={0}
          onClick={() => {
            setSelected([]);
            setIsHistory(true);
          }}
        >
          {t('History')}
        </Box>
      </Box>
      <MedicationTable
        meds={medications}
        isHistory={isHistory}
        selected={selected}
        setSelected={setSelected}
        titration_status_choices={titration_status_choices}
      />
    </Box>
  );
};

export default CurrentMedicationsWrapper;
