import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MenuIcon from '@mui/icons-material/Menu';
import QuizIcon from '@mui/icons-material/Quiz';
import SourceIcon from '@mui/icons-material/Source';
import {
  Alert,
  Box,
  Button,
  IconButton, Menu,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate, Route, Routes, useParams
} from 'react-router-dom';
import AlertDetails from '../components/alert/AlertDetails';
import CareTeam from '../components/care-team/CareTeam';
import CareTeamDetails from '../components/care-team/CareTeamDetails';
import {
  AddClinicalDataModal,
  Calendar,
  ClinicalDataDetails,
  ClinicalDataTable,
  ClinicalNoteModal, ClinicalNotePanel,
  DateRangePicker,
  Documents,
  LabResultDetails,
  LabResultsWrapper,
  MedicalActs,
  MedicalActSummary,
  MedicationTab,
  Overview,
  PatientActionPanel,
  PatientInformations,
  PatientProfileNavigation,
  QuestionnaireDetails,
  QuestionnaireSelector,
  Reports
} from '../components/patient-profile';
import PatientQuestionnairesCalendar from '../components/patient-profile/calendar/PatientQuestionnairesCalendar';
import QuestionnairesHistory from '../components/patient-profile/questionnaire/QuestionnairesHistory';
import AddVitalsDialog from '../components/vitals/AddVitalsDialog';
import VitalDetails from '../components/vitals/VitalDetails';
import { greyboxApiActions } from '../redux/api';
import { setIsLoading } from '../redux/pageLoader';
import { initializePatient } from '../redux/patient';
import { initializeDateRange } from '../redux/patientProfileDateRange';
import { getPermissions } from '../redux/permissions';

const StaticMenu = ({ patientUuid }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { clinic } = useSelector((state) => state.clinic);
  const { access } = useSelector((state) => state.user);

  return (
    !isMobile && (
      <Stack sx={{ minWidth: '275px', mr: 1, maxWidth: '300px' }} spacing={1}>
        <PatientActionPanel />
        {clinic.config.features_enable.medical_acts
          && access === 'PT' && <MedicalActSummary patientUuid={patientUuid} />}
        <PatientProfileNavigation />
      </Stack>
    )
  );
};

const PatientProfile = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { uuid } = useParams();
  const { t } = useTranslation();

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [addVitalsOpen, setAddVitalsOpen] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const [addDataOpen, setAddDataOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [consultationRecorded, setConsultationRecorded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { clinic } = useSelector((state) => state.clinic);
  const { access, accountId } = useSelector((state) => state.user);
  const { edit } = useSelector((state) => state.permissions);
  const dateRange = useSelector((state) => state.patientProfileDateRange);
  const patient = useSelector((state) => state.patient);

  const { medicalAct } = greyboxApiActions;
  const [addMedicalAct] = medicalAct.add();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(initializePatient(uuid));
    dispatch(getPermissions(accountId, uuid));
  }, [uuid]);

  useEffect(() => {
    if (patient.createdDate) {
      dispatch(initializeDateRange(clinic.id, clinic.config.features_enable.date_range, patient.createdDate));
    }
  }, [patient.createdDate]);

  const handleQuestionnaireClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setQuestionnaireOpen(false);
    }
  };

  // When user click yes on the top snackbar
  const handleMedicalActRegistration = () => {
    addMedicalAct({
      body: {
        patient: uuid,
        hcp: accountId,
        clinic: clinic.id
      },
    }).then(() => {
      setConsultationRecorded(true);
    });
  };

  const speedDialActions = [
    {
      icon: <LocalHospitalIcon />,
      name: 'Add vitals',
      onClick: () => setAddVitalsOpen(true),
      access: 'ALL',
    },
    {
      icon: <QuizIcon />,
      name: 'Fill a questionnaire',
      onClick: () => setQuestionnaireOpen(true),
      access: 'ALL',
    },
    {
      icon: <ListAltIcon />,
      name: 'Add clinical data',
      onClick: () => setAddDataOpen(true),
      access: ['PT'],
    },
    {
      icon: <SourceIcon />,
      name: 'Add clinical Note',
      onClick: () => setAddNoteOpen(true),
      access: ['PT'],
    },
  ];

  useEffect(() => {
    if (patient.isLoading || dateRange.range === null) {
      dispatch(setIsLoading(true));
    }

    if (!patient.isLoading && dateRange.range !== null) {
      dispatch(setIsLoading(false));
    }
  }, [patient.isLoading, dateRange.range]);

  if (patient.isLoading || dateRange.range === null) {
    return null;
  }

  // check if error loading the patient
  if (patient.isError) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      {isMobile && (
        <Box width="100%" display="flex">
          <IconButton
            color="primary"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{ ml: 'auto', mr: 1, my: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
            <PatientProfileNavigation />
          </Menu>
        </Box>
      )}
      {clinic.config.features_enable.medical_acts && access === 'PT' && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setSnackbarOpen(false)}
        >
          {consultationRecorded ? (
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
              {t('Consultation recorded')}
            </Alert>
          ) : (
            <Alert
              action={(
                <>
                  <Button onClick={() => setSnackbarOpen(false)}>
                    {t('No')}
                  </Button>
                  <Button onClick={() => handleMedicalActRegistration()}>
                    {t('Yes')}
                  </Button>
                </>
              )}
              onClose={() => setSnackbarOpen(false)}
              severity="info"
            >
              {`${t('Do you want to record a patient file consultation')} ?`}
            </Alert>
          )}
        </Snackbar>
      )}
      <Box display="flex">
        <StaticMenu patientUuid={uuid} />
        <Routes>
          <Route path='vitals/:vital' element={<VitalDetails patientUuid={uuid} />} />
          <Route path="questionnaire/:questionnaireId" element={<QuestionnaireDetails patientUuid={uuid} />} />
          <Route path="questionnaires-history" element={<QuestionnairesHistory patientUuid={uuid} />} />
          <Route path="lab-results" element={<LabResultsWrapper uuid={uuid} />} />
          <Route path="lab-results/:labResultCode" element={<LabResultDetails uuid={uuid} />} />
          <Route path="clinical-data" element={<ClinicalDataTable uuid={uuid} />} />
          <Route path="clinical-data/:clinicalDataCode" element={<ClinicalDataDetails />} />
          <Route path="care-team/:id" element={<CareTeamDetails patientUuid={uuid} />} />
          <Route path="care-team" element={<CareTeam uuid={uuid} />} />
          <Route path="medications" element={<MedicationTab uuid={uuid} />} />
          <Route path="reports" element={<Reports patientUuid={uuid} />} />
          <Route path="calendar" element={<Calendar patientUuid={uuid} />} />
          <Route path="questionnaires-calendar" element={<PatientQuestionnairesCalendar />} />
          <Route path="clinical-note" element={<ClinicalNotePanel uuid={uuid} />} />
          <Route path="overview" element={<Box sx={{ width: '100%' }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <DateRangePicker />
            </Box>
            <Overview patientUuid={uuid} />
          </Box>} />
          <Route path="alert" element={<AlertDetails type="alert" patientUuid={uuid} />} />
          <Route path="reminder" element={<AlertDetails type="reminder" patientUuid={uuid} />} />
          <Route path="information" element={<PatientInformations />} />
          <Route path="medical-acts" element={<MedicalActs patientUuid={uuid} />} />
          <Route path="documents" element={<Documents />} />
          <Route index element={<Navigate to="overview" />} />
          <Route path="*" element={<Navigate to="overview" />} />
        </Routes>
      </Box>
      {edit && clinic.config.features_enable.speed_dial && (
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: 'fixed', bottom: '8%', right: '5%' }}
          icon={<SpeedDialIcon />}
          onClose={() => setSpeedDialOpen(false)}
          onOpen={() => setSpeedDialOpen(true)}
          open={speedDialOpen}
        >
          {speedDialActions.map((action) => (
            (action.access === 'ALL' || action.access.includes(access)) && (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={t(action.name)}
                onClick={action.onClick}
              />
            )
          ))}
        </SpeedDial>
      )}
      {addVitalsOpen && (
        <AddVitalsDialog open={addVitalsOpen} handleClose={() => setAddVitalsOpen(false)} patientUuid={uuid} />
      )}
      <QuestionnaireSelector
        patientUuid={uuid}
        open={questionnaireOpen}
        handleClose={handleQuestionnaireClose}
      />
      {access !== 'P' && (
        <>
          <ClinicalNoteModal open={addNoteOpen} handleClose={() => setAddNoteOpen(false)} />
          <AddClinicalDataModal open={addDataOpen} patientUuid={uuid} handleClose={() => setAddDataOpen(false)} />
        </>
      )}
    </Box>
  );
};


export default PatientProfile;
