import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { medication_status } from '../../../../helper-functions';
import { greyboxApiActions } from '../../../../redux/api';

import 'moment/locale/fr';

const StopMedicationModal = (props) => {
  const { selectedMeds = [], open, handleClose } = props;
  const { t } = useTranslation();
  const { medication } = greyboxApiActions;
  const [updateMedication, result] = medication.update();
  const minDate = moment.max(selectedMeds.map((med) => moment(med.startDate)));
  const [date, setDate] = useState(
    moment.max(moment(minDate).add(1, 'days'), moment()).format('YYYY-MM-DD')
  );

  const handleStopMedication = async () => {
    await Promise.all(
      selectedMeds.map((med) =>
        updateMedication({
          id: med.uuid,
          body: {
            status: medication_status(med.start_date, date),
            end_date: moment(date).format('YYYY-MM-DD'),
          },
        })
      )
    );
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      disableBackdropClick={result.isLoading}
      maxWidth="sm"
    >
      <DialogTitle>{t('Stop Medication')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0px 0 10px 0',
            borderBottom: '1px solid rgb(241, 242, 243)',
            alignItems: 'center',
          }}
          data-cy="multiCatWrapper"
        >
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: '#95989a',
              fontSize: 14,
              width: '50%',
            }}
          >
            {t('Medication')}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: '#95989a',
              fontSize: 14,
              width: '33%',
            }}
          >
            {t('Daily Dose')}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: '#95989a',
              fontSize: 14,
              textAlign: 'right',
              width: '17%',
            }}
          >
            {t('Start')}
          </Box>
        </Box>
        {selectedMeds.map((med, idx) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '12px 0',
              borderBottom: '1px solid rgb(241, 242, 243)',
              alignItems: 'center',
            }}
            key={med.uuid + idx}
            data-cy="multiGroupWrapper"
          >
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 500,
                textTransform: 'capitalize',
                color: '#454545',
                fontSize: 14,
                paddingRight: '12px',
                width: 'calc(50% - 12px)',
              }}
            >
              {med.name.toLowerCase()}
            </Box>
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                color: '#454545',
                fontSize: 14,
                width: '33%',
              }}
            >
              {med.daily} {med.unit}
            </Box>
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                color: '#454545',
                fontSize: 14,
                width: '17%',
                textAlign: 'right',
              }}
            >
              {med.startDate}
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '16px 0 0px 0',
            height: '63px',
            alignItems: 'flex-start',
          }}
          data-cy="multiEndWrapper"
        >
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: '#454545',
              marginTop: '20px',
              fontSize: 14,
            }}
          >
            {t('End')}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              color: '#454545',
              marginTop: '5px',
              fontSize: 14,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date"
                value={moment(date)}
                onChange={(d) => setDate(d)}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleStopMedication}
          variant="contained"
          disabled={result.isLoading}
          color="primary"
        >
          {result.isLoading ? <CircularProgress size={14} /> : t('Stop')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopMedicationModal;
