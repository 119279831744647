import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, InputAdornment, Box, CircularProgress } from '@mui/material';
import { LuSparkles } from "react-icons/lu"; // Importing from react-icons
import AudioIcon from '@mui/icons-material/KeyboardVoice';
import StopIcon from '@mui/icons-material/Stop';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';
import { useFormikContext } from 'formik';
import { processDosage } from './utils';

const AiInstructionInputField = ({ medication_input }) => {
    const { t } = useTranslation();
    const { convertDosage } = greyboxApiActions;
    const [postConvertDosage, result] = convertDosage.add();
    const [inputValue, setInputValue] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state for sending
    const mediaRecorderRef = useRef(null); // To keep track of the MediaRecorder instance
    const audioChunks = useRef([]); // Store audio data in chunks
    const { setValues, values } = useFormikContext();

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSendClick = async () => {
        // Start loading state
        setIsLoading(true);

        const result = await postConvertDosage({ body: { medication_input: medication_input, dosage_input: inputValue } });

        const newDosage = processDosage(result.data);

        setValues({
            ...values, // Keep existing values
            ...newDosage,
            doseValue: newDosage.doseValue ? newDosage.doseValue : values.doseValue,
            doseUnit: newDosage.doseUnit ? newDosage.doseUnit : values.doseUnit,
        });

        // Reset after sending
        setIsLoading(false);
        setInputValue(''); // Clear input after sending
    };

    const handleAudioClick = async () => {
        if (isRecording) {
            // Stop recording
            mediaRecorderRef.current.stop();
        } else {
            // Start recording
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };
            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                audioChunks.current = []; // Clear the chunks

                // Upload audioBlob to postConvertDosage
                setIsLoading(true); // Start loading
                const formData = new FormData();
                formData.append('medication_input', medication_input);
                formData.append('audio_file', audioBlob, 'recording.wav');

                const result = await postConvertDosage({ body: formData });

                const newDosage = processDosage(result.data);

                setValues({
                    ...values, // Keep existing values
                    ...newDosage
                });

                // Stop loading after processing
                setIsLoading(false);
            };
            mediaRecorderRef.current.start();
            console.log('Recording started');
        }
        setIsRecording(!isRecording); // Toggle recording state
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent form submission on Enter key
            handleSendClick();
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
                fullWidth
                multiline // Enable multiline input
                placeholder={t('AI powered dosage instructions')}
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Disable form submit on Enter
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LuSparkles color="primary" size={24} /> {/* Using LuSparkles from react-icons */}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                color="primary"
                                onClick={inputValue && !isLoading ? handleSendClick : handleAudioClick}
                                disabled={isLoading} // Disable button when loading
                            >
                                {isLoading ? (
                                    <CircularProgress size={24} /> // Show spinner when loading
                                ) : inputValue ? (
                                    <SendIcon />
                                ) : isRecording ? (
                                    <StopIcon />
                                ) : (
                                    <AudioIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                }}
            />
        </Box>
    );
};

export default AiInstructionInputField;
