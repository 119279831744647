import {
  Box,
  Theme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ChatBox, ThreadSelector } from '../components/chat';

const MessagesDashboard = () => {
  const { threadId } = useParams()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    if (!threadId) {
      return (
        <Box sx={{ height: 'calc(100vh - 88px)' }}>
          <ThreadSelector />
        </Box>
      );
    }

    return (
      <Box sx={{ height: 'calc(100vh - 88px)' }}>
        <ChatBox threadId={threadId}/>
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      height: 'calc(100vh - 100px)',
    }}
    >
      <Box sx={{ flex: 1 }}>
        <ThreadSelector />
      </Box>
      {threadId && (
        <Box sx={{ width: '100%' }}>
          <ChatBox threadId={threadId} />
        </Box>
      )}
    </Box>
  );
};

export default MessagesDashboard;
