import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import 'moment/locale/fr';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { greyboxApiActions } from '../../../../redux/api';
import DialogTitle from '../../../DialogTitle';

const DeleteMedicationModal = (props) => {
  const { open, handleClose, selectedMedications } = props;
  const { t } = useTranslation();
  const { medication } = greyboxApiActions;
  const [deleteMedication, result] = medication.update();

  const handleDelete = async () => {
    await Promise.all(
      selectedMedications.map((med) =>
        deleteMedication({ id: med.uuid, body: { status: 'entered-in-error' } })
      )
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>{t('Delete Medications')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
            alignItems: 'center',
          }}
        >
          <Typography color="textSecondary" sx={{ width: '50%' }}>
            {t('Medication')}
          </Typography>
          <Typography color="textSecondary" sx={{ width: '33%' }}>
            {t('Daily Dose')}
          </Typography>
          <Typography color="textSecondary" sx={{ textAlign: 'right', width: '17%' }}>
            {t('Start')}
          </Typography>
        </Box>
        {selectedMedications.map((medInfo) => (
          <Box key={medInfo.uuid}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 1,
                borderBottom: '1px solid',
                borderColor: 'divider',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'capitalize',
                  paddingRight: '12px',
                  width: 'calc(50% - 12px)',
                }}
              >
                <b>{medInfo.name.toLowerCase()}</b>
              </Typography>
              <Typography variant="body2" sx={{ width: '33%' }}>
                {medInfo.daily} {medInfo.unit}
              </Typography>
              <Typography variant="body2" sx={{ width: '17%', textAlign: 'right' }}>
                {medInfo.startDate}
              </Typography>
            </Box>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleDelete} color="primary" disabled={result.isLoading}>
          {result.isLoading ? <CircularProgress size={14} /> : t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteMedicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedMedications: PropTypes.arrayOf(PropTypes.object),
};

DeleteMedicationModal.defaultProps = {
  selectedMedications: [],
};

export default DeleteMedicationModal;
