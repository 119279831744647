import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Taken_no_halo } from '../../../tk-ui';
import MedicationModal from '../medicationTable/MedicationModal';
import { medication_list_style } from './suggestedMedications_style';

const MedicationList = (props) => {
  const {
    medication_list,
    acc_type,
    activeCodes,
    uuid,
  } = props;
  const { t, i18n } = useTranslation();
  const check_taken_med = (med) => (
    activeCodes.length > 0 && activeCodes.some((el) => el === med.active_ingredient_codes[0])
  );
  const lang = i18n.resolvedLanguage;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = (med) => {
    if (acc_type === 'PT') {
      setSelected(med.en);
      setOpen(true);
    }
  };

  const handleKeyPress = (e, med) => {
    if (e.key === 'Enter') {
      handleClick(med);
    }
  };

  return (
    <div style={medication_list_style.wrapper}>
      {medication_list.map((med, index) => (
        <div key={index}>
          {check_taken_med(med) ? (
            <div
              style={medication_list_style.rowWrapperActive}
              key={index}
              data-cy={`suggestedMedItem-${med.en.replace(/\s+/g, '')}`}
            >
              <div key={index} style={medication_list_style.item_active}>
                {med[lang]}
              </div>
              <div style={medication_list_style.active_text}>
                <Taken_no_halo data-cy="medTakenIcon" />
              </div>
            </div>
          ) : (
            <div
              style={medication_list_style.rowWrapper}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => handleKeyPress(e, med)}
              underline="none"
              key={`${index}link`}
              data-cy={`suggestedMedItem-${med.en.replace(/\s+/g, '')}`}
              onClick={() => handleClick(med)}
            >
              <div key={index} style={medication_list_style.item}>
                {med[lang]}
              </div>
              {(acc_type === 'PT') && (
                <span
                  data-cy={`${med[lang]}-${index}`}
                  style={medication_list_style.plusIcon}
                >
                  {t(' + ')}
                </span>
              )}
            </div>
          )}
        </div>
      ))}
      <MedicationModal
        acc_type={acc_type}
        patientId={uuid}
        open={open}
        handleClose={() => setOpen(false)}
        search={selected}
      />
    </div>
  );
};

export { MedicationList };
