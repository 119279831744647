import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import FormikTextField from '../form-inputs/FormikTextField';
import ErrorMessage from '../form-inputs/ErrorMessage';

const ClinicInformation = () => {
  const { t } = useTranslation();
  const { company } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isFetching, isError, refetch } = company.get(clinic.id);
  const [updateClinic, results] = company.update();
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Paper sx={{ p: 1, mb: 1 }}>
      {isFetching ? (
        <>
          <Typography variant="h5" sx={{ m: 1 }}>
            <Skeleton width="50%" />
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 2,
              p: 2,
            }}
          >
            {[1, 2, 3, 4].map((index) => (
              <Skeleton key={index} variant="rectangular" height={56} />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Skeleton variant="rectangular" width={100} height={36} sx={{ mr: 1 }} />
            <Skeleton variant="rectangular" width={100} height={36} />
          </Box>
        </>
      ) : isError ? (
        <Alert severity="error">
          <AlertTitle>{t('Error')}</AlertTitle>
          {t('Failed to load clinic information.')}
          <Button onClick={refetch}>{t('Retry')}</Button>
        </Alert>
      ) : (
        <>
          <Typography variant="h5" sx={{ m: 1 }}>
            {t('Edit Clinic Information')}
          </Typography>
          <Formik
            initialValues={{
              name: data?.name || '',
              address: data?.address || '',
              phone_num: data?.phone_num || '',
              email: data?.email || '',
            }}
            enableReinitialize
            validationSchema={Yup.object({
              name: Yup.string()
                .required(t('required'))
                .min(2, t('Name must be at least 2 characters')),
              address: Yup.string(),
              phone_num: Yup.string()
                .required(t('required'))
                .matches(
                  /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}(?: *(?:x|ext)\.? *[0-9]+)?$/im,
                  t('Invalid phone number')
                ),
              email: Yup.string()
                .email(t('Invalid email'))
                .required(t('required')),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              setErrorMessage('');
              updateClinic({ id: clinic.id, body: values }).unwrap().then(it => setSubmitting(false)).catch(error => {
                setSubmitting(false);
                const errors = error.data || {};
                if (errors.error) {
                  setErrorMessage(errors.error);
                }
                setErrors(errors);
              });
            }}
          >
            {({ resetForm, dirty, isSubmitting }) => (
              <Form>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                    p: 2,
                  }}
                >
                  <FormikTextField name="name" label={t('Name')} required />
                  <FormikTextField name="address" label={t('Address')} />
                  <FormikTextField
                    name="phone_num"
                    label={t('Phone')}
                    type="tel"
                    required
                  />
                  <FormikTextField
                    name="email"
                    label={t('Email')}
                    type="email"
                    required
                  />
                </Box>

                {/* Display Error Message */}
                {errorMessage && <ErrorMessage message={errorMessage} />}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="secondary"
                    sx={{ mr: 1 }}
                    onClick={resetForm}
                    disabled={!dirty || isSubmitting}
                  >
                    {t('Reset')}
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    type="submit"
                    disabled={!dirty || isSubmitting}
                  >
                    {t('Save')}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Paper>
  );
};

export default ClinicInformation;
