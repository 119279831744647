import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import moment from 'moment';
import MuiMarkdown from 'mui-markdown';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getComparator, stableSort
} from '../../../../helper-functions';
import {
  NextDoseOutlined,
  Sorted,
  Sorting, Taken_no_halo, TimeOutlined, Tooltips
} from '../../../tk-ui';
import DeleteMedicationModal from './DeleteMedicationModal';
import MedicationModal from './MedicationModal';
import StopMedicationModal from './StopMedicationModal';
import { formatDosageToMarkdown } from './utils';

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const { t } = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const cellSettings = [
    { id: 'name', align: 'left', label: t('Medication'), sortable: false, minWidth: '150px' },
    { id: 'statusName', align: 'left', label: t('Titration status'), sortable: false, minWidth: '120px' },
    { id: 'dosage', align: 'left', label: t('Dosage'), sortable: false, minWidth: '100px' },
    { id: 'startDate', align: 'left', label: t('Start'), sortable: true, minWidth: '80px' },
    { id: 'endDate', align: 'left', label: t('End'), sortable: true, minWidth: '80px' },
  ];

  return (
    <TableHead
      sx={{
        height: 30,
        padding: 0,
        margin: 0,
        borderTop: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.default',
      }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount === 0 ? false : numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all medications' }}
            color="primary"
          />
        </TableCell>
        {cellSettings.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={headCell.sortable && orderBy === headCell.id ? order : false}
            sx={{
              fontFamily: 'Roboto',
              fontSize: 14,
              fontWeight: 500,
              padding: 1,
              minWidth: headCell.minWidth,
              color: orderBy === headCell.id ? 'primary.main' : 'inherit',
              cursor: headCell.sortable ? 'pointer' : 'default',
            }}
            onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {headCell.label}
              {headCell.sortable && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.5 }}>
                  {orderBy === headCell.id ? (
                    <Box
                      sx={{
                        transform: order === 'desc' ? 'rotate(180deg)' : 'none',
                        transition: 'all 200ms ease',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Sorted />
                    </Box>
                  ) : (
                    <Sorting />
                  )}
                </Box>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const MedicationActions = (props) => {
  const {
    medications,
    selected,
    setSelected,
    isHistory,
  } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const { t } = useTranslation();
  const { edit } = useSelector((state) => state.permissions);
  const { access } = useSelector((state) => state.user);

  const selectedMeds = useMemo(() => {
    if (medications && selected) {
      return medications.filter(it => selected.includes(it.uuid));
    }
    else {
      return [];
    }
  }, [medications, selected]);

  const enabled = access === 'PT';

  const handleEditClick = () => {
    setAddModalOpen(true);
  }

  return (
    <Toolbar disableGutters variant="dense" data-cy="toolbar">
      <Stack spacing={1} direction="row" sx={{ ml: 1 }}>
        {!isHistory && (
          <>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="small"
              disabled={!enabled || !edit}
              onClick={() => setAddModalOpen(true)}
              startIcon={<AddIcon />}
            >
              {t('Add')}
            </Button>
            <MedicationModal
              open={addModalOpen}
              selectedId={selected[0]}
              handleClose={() => {
                setAddModalOpen(false);
                setSelected([]);
              }}
            />
            <Button
              variant="outlined"
              disabled={!enabled || selectedMeds.length !== 1 || !edit}
              size="small"
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              {t('Edit')}
            </Button>
            <Button
              variant="outlined"
              disabled={!enabled || selectedMeds.length === 0 || !edit}
              onClick={() => setStopModalOpen(true)}
              size="small"
              startIcon={<NotInterestedIcon />}
            >
              {t('Stop')}
            </Button>
            {stopModalOpen && (
              <StopMedicationModal
                open={stopModalOpen}
                selectedMeds={selectedMeds}
                handleClose={() => {
                  setStopModalOpen(false);
                  setSelected([]);
                }}
              />
            )}
          </>
        )}
        <Button
          disabled={!enabled || selectedMeds.length === 0}
          variant="outlined"
          size="small"
          startIcon={<DeleteOutlineIcon />}
          onClick={() => setDeleteModalOpen(true)}
          sx={{ borderColor: 'error.main', color: 'error.main' }}
        >
          {t('Delete')}
        </Button>
        <DeleteMedicationModal
          open={deleteModalOpen}
          handleClose={() => {
            setDeleteModalOpen(false);
            setSelected([]);
          }}
          selectedMedications={selectedMeds}
        />
      </Stack>
    </Toolbar>
  );
};

const MedicationTable = (props) => {
  const {
    meds,
    isHistory,
    selected,
    setSelected,
  } = props;

  const { i18n, t } = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(isHistory ? 'endDate' : 'startDate');
  const lang = i18n.resolvedLanguage;

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = meds.map((n) => n.uuid);
      setSelected(newSelecteds);
    }
    else {
      setSelected([]);
    }
  };

  useEffect(() => {
    setOrderBy(isHistory ? 'endDate' : 'startDate');
  }, [isHistory]);

  const handleClick = (uuid) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(uuid)) {
        return prevSelected.filter((item) => item !== uuid);
      } else {
        return [...prevSelected, uuid];
      }
    });
  };

  moment.updateLocale('en', {
    relativeTime: {
      past: '%s late',
    },
  });

  moment.updateLocale('fr', {
    relativeTime: {
      past: '%s de retard',
    },
  });

  return (
    <Paper sx={{ boxShadow: '0 0 1px 1px rgba(0,0,0,0.1)', minWidth: 940 }} data-cy="paper">
      <MedicationActions
        medications={meds}
        isHistory={isHistory}
        selected={selected}
        setSelected={setSelected}
      />
      <TableContainer component={Paper} sx={{ overflowY: 'hidden' }}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          sx={{ borderTop: '1px solid #DEDEDE' }}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            isHistory={isHistory}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={meds.length}
          />
          <TableBody>
            {meds.length > 0 ? (
              stableSort(meds, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = selected.includes(row.uuid);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uuid}
                      selected={isItemSelected}
                      data-cy="tableRow"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => handleClick(row.uuid)}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          data-cy="currentMedCheckbox"
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        data-cy="cellWrapper"
                        sx={{
                          borderRight: '1px solid #DEDEDE',
                          padding: '5px 0 3px 7px',
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#454545',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              width: isHistory ? '88%' : '65.5%',
                              minHeight: 40,
                              fontWeight: 500,
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() => handleClick(row.uuid)}
                          >
                            {row.name}
                            {' '}
                            {row.isNotSuggested && !isHistory && (
                              <Tooltips text={t('Non-suggested medication')} delayTime={200}>
                                <Box
                                  component="span"
                                  sx={{
                                    position: 'absolute',
                                    width: 15,
                                    height: 12,
                                    transform: 'translateX(6px) translateY(1px) scale(0.96)',
                                  }}
                                >
                                  <Taken_no_halo data-cy="medNotTakenIcon" fills="#f49342" />
                                </Box>
                              </Tooltips>
                            )}
                            {!row.isNotSuggested && !isHistory && (
                              <Tooltips text={t('Suggested medication')} delayTime={200}>
                                <Box
                                  component="span"
                                  sx={{
                                    position: 'absolute',
                                    width: 15,
                                    height: 12,
                                    transform: 'translateX(6px) translateY(1px) scale(0.96)',
                                  }}
                                >
                                  <Taken_no_halo data-cy="medTakenIcon" fills="#50b83c" />
                                </Box>
                              </Tooltips>
                            )}
                            {row.lastActiveDay && (
                              <Tooltips text={t('Last day')} delayTime={200}>
                                <Box
                                  component="span"
                                  sx={{
                                    position: 'absolute',
                                    width: 18,
                                    height: 12,
                                    transform: 'translateX(25px) translateY(-0.6px) scale(0.99)',
                                  }}
                                >
                                  <TimeOutlined data-cy="lastActiveDayIcon" fills="#dea533" />
                                </Box>
                              </Tooltips>
                            )}
                            {row.nextDoseDate && row.nextDose && !isHistory && (
                              <Tooltips
                                text={(
                                  <>
                                    <div style={{ paddingBottom: '2px' }}>
                                      {`${t('Next Step')}: + ${row.nextDose} ${row.unit}`}
                                    </div>
                                    <div>
                                      {moment(row.nextDoseDate).locale(lang).fromNow()}
                                    </div>
                                  </>
                                )}
                                delayTime={200}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    position: 'absolute',
                                    width: 18,
                                    height: 12,
                                    transform: row.lastActiveDay
                                      ? 'translateX(44.5px) translateY(-1px) scale(1.1)'
                                      : 'translateX(24px) translateY(-1px) scale(1.1)',
                                  }}
                                >
                                  <NextDoseOutlined data-cy="nextIcon" fills={row.nextDoseTime > 1 ? 'info' : 'warning'} />
                                </Box>
                              </Tooltips>
                            )}
                            {row.ingredient.map((molecule) => (
                              <Box
                                key={molecule.drugUuid}
                                sx={{
                                  fontSize: 12,
                                  color: '#95989a',
                                  fontWeight: 500,
                                  textTransform: 'capitalize',
                                }}
                              >
                                {lang === 'en' ? molecule.molecule : molecule.moleculeFr}
                                {' '}
                                {molecule.dosageUnit === 'nan' ? (
                                  <Box component="span" sx={{ textTransform: 'lowercase', fontWeight: 400 }}>
                                    ({lang === 'en' ? molecule.strength : molecule.strengthFr})
                                  </Box>
                                ) : (
                                  <Box component="span" sx={{ textTransform: 'lowercase', fontWeight: 400 }}>
                                    ({lang === 'en' ? molecule.strength : molecule.strengthFr} / {lang === 'en' ? molecule.dosageUnit : molecule.dosageUnitFr})
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ borderRight: '1px solid #DEDEDE', padding: '0 9px' }}>
                        {row.statusName ? (
                          <Box sx={{ position: 'relative', cursor: 'auto' }} data-cy="graphs">
                            <Box
                              component="span"
                              sx={{
                                fontSize: 13,
                                fontWeight: 400,
                                borderRadius: '10px',
                                padding: '1.75px 11px',
                                overflow: 'hidden',
                                color: '#ffffff',
                                whiteSpace: 'nowrap',
                                background: row.statusColor,
                              }}
                            >
                              {t(row.statusName)}
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            {!isHistory ? (
                              <Box
                                component="span"
                                sx={{
                                  background: '#637381',
                                  borderRadius: '10px',
                                  fontSize: 13,
                                  fontWeight: 400,
                                  padding: '1.75px 11px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  color: '#ffffff',
                                }}
                              >
                                {t('No available plan')}
                              </Box>
                            ) : (
                              <Box component="span">---</Box>
                            )}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid #DEDEDE',
                          padding: '0 9px',
                          color: '#454545',
                          fontWeight: 400,
                          position: 'relative',
                        }}
                        onClick={() => handleClick(row.uuid)}
                      >
                        <MuiMarkdown>{row.dosage ? formatDosageToMarkdown(row.dosage, t) : '---'}</MuiMarkdown>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid #DEDEDE',
                          padding: '0 9px',
                          color: '#454545',
                          fontWeight: 400,
                          position: 'relative',
                        }}
                        onClick={() => handleClick(row.uuid)}
                      >
                        {row.startDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '0 9px',
                          color: '#454545',
                          fontWeight: 400,
                          minWidth: 80,
                          textAlign: 'center',
                        }}
                        onClick={() => handleClick(row.uuid)}
                      >
                        {row.lastActiveDay ? (
                          <Box component="span" sx={{ fontWeight: 500, letterSpacing: '-0.15px' }} data-cy="isLast">
                            {row.endDate}
                          </Box>
                        ) : (
                          <Box component="span" data-cy="isNotLast">{row.endDate}</Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                  {t('No medications available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MedicationTable;
