import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Popper from '@mui/material/Popper';
import { useSelector } from 'react-redux';
import {
  Typography, useMediaQuery, Box, Stack, Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactDOMServer from 'react-dom/server';
import { greyboxApiActions } from '../../redux/api';
import { usePatientWebSocket } from '../../hooks';
import ChartContainer from './ChartContainer';
import QuestionnaireAnswers from '../patient-profile/questionnaire/QuestionnaireAnswers';
import { useTranslation } from 'react-i18next';

/**
 * @param {Array} records - Data from questionnaireRecord API call
 * @returns {Array} Parsed data to be used in the graph
 */
export const parseQuestionnaireRecordScore = (records) => (
  {
    name: 'Score',
    data: records.map((record) => (
      {
        x: moment(record.answer_timestamp).local(),
        y: parseInt(record.score?.value || 0, 10),
        alert: record.score?.threshold_status || false,
        color: record.score_range?.color || 'black',
        description: record.score_range?.description || '',
        id: record.id,
      }
    )),
  }
);

/**
 * Generates custom markers including alert markers with their specific colors.
 * @param {Array} data - Parsed data from parseQuestionnaireRecordScore
 * @returns {Array} Custom markers for the graph
 */
export const generateCustomMarkers = (data) => {
  const markers = [];
  const parsedData = parseQuestionnaireRecordScore(data);
  parsedData.data.forEach((item, idx) => {
    markers.push({
      size: item.alert ? 6 : 5, // Slightly larger size for alerts
      seriesIndex: 0,
      dataPointIndex: idx,
      strokeColor: item.color,
      fillColor: item.color,
      strokeWidth: 3,
      shape: 'circle',
    });
  });
  return markers;
};

const CustomTooltip = ({
  date, description,
}) => (
  <div style={{ padding: '8px' }}>
    <Box>
      <Typography variant="caption">
        {moment(date).format('lll')}
      </Typography>
    </Box>
    <Divider />
    {description || ''}
  </div>
);

/**
 * A graph showcasing the scores of the selected questionnaire of the current patient through time.
 * Every occurrence that generated an alert is marked as red.
 */
const QuestionnaireResultChart = (props) => {
  const {t} = useTranslation();
  const [alertMarkers, setAlertMarkers] = useState([]);
  const { clinic } = useSelector((state) => state.clinic);
  const { range } = useSelector((state) => state.patientProfileDateRange);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const isMobile = useMediaQuery((s) => s.breakpoints.down('md'));
  const { questionnaireId, patientUuid, detailed = false, autoHeight = false } = props;
  const { questionnaireAnswers, questionnaire } = greyboxApiActions;
  const questionnaireSelector = questionnaire.list({ id: questionnaireId, clinic: clinic.id });
  const { data = [], isFetching, refetch } = questionnaireAnswers.list({
    patient: patientUuid,
    questionnaire: questionnaireId,
    answer_timestamp__range: range,
    clinic: clinic.id,
    completed: true,
  });
  const theme = useTheme();
  const [series, setSeries] = useState([]);

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (data.length > 0) {
      const customMarkers = generateCustomMarkers(data);
      setAlertMarkers(customMarkers);
      const seriesData = [parseQuestionnaireRecordScore(data)];
      setSeries(seriesData);
    }
  }, [data]);

  const getHeights = () => {
    if (isMobile) {
      return '280px';
    } if (autoHeight) {
      return '400px';
    }
    return '100%';
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Questionnaire_Reference_Record') {
        if (lastJsonMessage.data.questionnaire_id === questionnaireId) {
          refetch();
        }
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (data.length > 0) {
      const customMarkers = generateCustomMarkers(data);
      setAlertMarkers(customMarkers);
    }
  }, [data]);

  if (isFetching || questionnaireSelector.isFetching) {
    return <Skeleton variant="rect" width="100%" height="420px" />;
  }

  const thresholdAnnotations = [];

  if (!questionnaireSelector.isLoading) {
    const {
      max_high_threshold,
      min_high_threshold,
      max_mod_threshold,
      min_mod_threshold,
      max_low_threshold,
      min_low_threshold
    } = questionnaireSelector.data;

    if (max_high_threshold) {
      thresholdAnnotations.push({
        y: parseFloat(max_high_threshold, 10),
        strokeDashArray: 2,
        borderColor: '#dc3545', // Red for high threshold
        label: {
          borderColor: '#dc3545',
          style: {
            color: '#fff',
            background: 'rgba(220, 53, 69, 0.5)' // Red with 50% transparency
          },
          text: t('Max High'),
        }
      });
    }

    if (min_high_threshold) {
      thresholdAnnotations.push({
        y: parseFloat(min_high_threshold, 10),
        strokeDashArray: 2,
        borderColor: '#dc3545', // Red for high threshold
        label: {
          borderColor: '#dc3545',
          style: {
            color: '#fff',
            background: 'rgba(220, 53, 69, 0.5)' // Red with 50% transparency
          },
          text: t('Min High'),
        }
      });
    }

    if (max_mod_threshold) {
      thresholdAnnotations.push({
        y: parseFloat(max_mod_threshold, 10),
        strokeDashArray: 2,
        borderColor: '#ffc107', // Yellow for moderate threshold
        label: {
          borderColor: '#ffc107',
          style: {
            color: '#fff',
            background: 'rgba(255, 193, 7, 0.5)' // Yellow with 50% transparency
          },
          text: t('Max Moderate'),
        }
      });
    }

    if (min_mod_threshold) {
      thresholdAnnotations.push({
        y: parseFloat(min_mod_threshold, 10),
        strokeDashArray: 2,
        borderColor: '#ffc107', // Yellow for moderate threshold
        label: {
          borderColor: '#ffc107',
          style: {
            color: '#fff',
            background: 'rgba(255, 193, 7, 0.5)' // Yellow with 50% transparency
          },
          text: t('Min Moderate'),
        }
      });
    }

    if (max_low_threshold) {
      thresholdAnnotations.push({
        y: parseFloat(max_low_threshold, 10),
        strokeDashArray: 2,
        borderColor: '#17a2b8', // Blue for low threshold
        label: {
          borderColor: '#17a2b8',
          style: {
            color: '#fff',
            background: 'rgba(23, 162, 184, 0.5)' // Blue with 50% transparency
          },
          text: t('Max Low'),
        }
      });
    }

    if (min_low_threshold) {
      thresholdAnnotations.push({
        y: parseFloat(min_low_threshold, 10),
        strokeDashArray: 2,
        borderColor: '#17a2b8', // Blue for low threshold
        label: {
          borderColor: '#17a2b8',
          style: {
            color: '#fff',
            background: 'rgba(23, 162, 184, 0.5)' // Blue with 50% transparency
          },
          text: t('Min Low'),
        }
      });
    }
  }

  const options = {
    dataLabels: {
      enabled: false,
    },
    chart: {
      foreColor: theme.palette.text.primary,
      events: {
        click: function (event, chartContext, { dataPointIndex, seriesIndex }) {
          console.log('click', dataPointIndex, seriesIndex);
          if (dataPointIndex >= 0 && dataPointIndex <= series[seriesIndex].data.length) {
            const item = series[seriesIndex].data[dataPointIndex];
            setAnchorEl(event);
            setSelectedRecord({
              recordIndex: dataPointIndex,
              recordId: item.id,
              anchorPosition: { top: event.clientY, left: event.clientX },
            });
          }
        },
      },
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(0),
      },
      min: questionnaireSelector.data.config?.y_origin || 0,
    },
    colors: ['black'],
    stroke: { curve: 'straight' },
    markers: {
      size: 5,
      strokeColor: 'black',
      colors: ['white'],
      strokeWidth: 3,
      shape: 'circle',
      discrete: alertMarkers,
    },
    legend: {
      horizontalAlign: 'left',
    },
    tooltip: {
      shared: true,
      enabledOnSeries: [0],
      x: {
        format: 'dd MMM HH:mm',
      },
      custom: function createTooltip({
        series, seriesIndex, dataPointIndex, w,
      }) {
        const data = w.config.series[seriesIndex].data[dataPointIndex];

        // Render custom tooltip component to a string
        const tooltipContent = ReactDOMServer.renderToString(
          <CustomTooltip
            date={data.x}
            description={data.description}
          />,
        );

        return tooltipContent;
      },
    },
    annotations: {
      position: 'back',
      yaxis: thresholdAnnotations,
    },
  };

  if (!questionnaireSelector.data.has_score) {
    return null;
  }

  return (
    <>
      <ChartContainer
        patientUuid={patientUuid}
        url={`questionnaire/${questionnaireId}`}
        name={questionnaireSelector.data.name}
        detailed={detailed}
      >
        <ReactApexChart
          options={options}
          series={[parseQuestionnaireRecordScore(data)]}
          height={getHeights()}
          type="line"
        />
      </ChartContainer>
      {!!selectedRecord && (
        <Popper
          placement="bottom"
          anchorEl={anchorEl}
          open={!!selectedRecord}
          onClose={() => selectedRecord(null)}
          style={{ position: 'fixed', top: selectedRecord.anchorPosition.top, left: selectedRecord.anchorPosition.left }}
          disablePortal={false}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
        >
          <QuestionnaireAnswers
            recordIndex={selectedRecord.recordIndex}
            recordId={selectedRecord.recordId}
            handleClose={() => setSelectedRecord(null)}
          />
        </Popper>
      )}
    </>
  );
};

export default QuestionnaireResultChart;
