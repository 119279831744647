import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button, Card, CardActions, CardContent, CardHeader,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import { getExtensionValueFromType } from '../../../utils';
import Row from '../../Row';
import Telecom from '../Telecom';
import {
  Clinic,
  EthnicGroup,
  FormTextField,
  Hin,
  Labels,
  Language, MultiSelect, Pharmacy, PhoneNumber, Sex,
  formatHinWithSpaces,
  hinNumberSchema,
} from '../../form-inputs';
import BirthDate, { formatBirthDateForPost } from '../../form-inputs/BirthDate';
import { ZipCode, zipCodeSchema } from '../../form-inputs/ZipCode';
import ArchiveModal from '../../modals/ArchiveModal';
import AddIdentifierDialog from '../../modals/AddIdentifierDialog';

const BasicInfoTab = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const configs = clinic.config.features_enable;
  const { uuid } = useParams();
  const {
    account, diagnosis,
  } = greyboxApiActions;
  const { data = [] } = account.get(uuid);
  const [updateAccount] = account.update();
  const [open, setOpen] = useState(false);
  const [identifierModalOpen, setIdentifierModalOpen] = useState(false);
  const [editIdentifierIndex, setEditIdentifierIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  const { access } = useSelector((state) => state.user);
  const diagnosisSelector = diagnosis.list({ clinic: clinic.id });

  const informations = useFormik({
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      labels: data.labels || [],
      email: data.email || '',
      birthDate: data.birthDate ? moment(data.birthDate) : null,
      telecom: data.telecom || [],
      phoneNumber: data.phoneNumber || '',
      zipCode: data.zipCode || '',
      hin_number: formatHinWithSpaces(data.hin?.hin_number),
      clinic: data.company?.[0] || clinic.id,
      gender: data.gender || 'U',
      language: data.language || 'fr',
      ethnic_group: data.ethnic_group || 'U',
      primaryDiagnosis: data?.diagnosisDetails
        ?.filter((d) => data.diagnosis.includes(d.diagnosis_key))
        .map((d) => ({ id: d.uuid, label: d.diagnosis_name, key: d.diagnosis_key })) || [],
      secondaryDiagnosis: diagnosisSelector.data
        ?.filter((d) => data.secondary_diagnosis.includes(d.uuid))
        .map((d) => ({ id: d.uuid, label: d.diagnosis_name, key: d.diagnosis_key })) || [],
      staffs: data.staffs ? data.staffs.map((staff) => ({ id: staff })) : [],
      pharmacy: getExtensionValueFromType(data.extension, 'patient-preferred-pharmacy'),
      identifier: data.identifier || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      birthDate: Yup.date().nullable(),
      email: Yup.string().email(t('Invalid email address')),
      zipCode: zipCodeSchema,
      phone_number: Yup.string(),
      hin_number: hinNumberSchema,
      clinic: Yup.string(),
      pharmacy: Yup.string(),
      primaryDiagnosis: Yup.array().of(Yup.object()),
      secondaryDiagnosis: Yup.array().of(Yup.object()),
      labels: Yup.array().of(Yup.object()),
      identifier: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(t('Required')),
          system: Yup.string().required(t('Required')),
        })
      ),
    }),
    onSubmit: (values) => {
      const body = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== informations.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      if (body.staffs) {
        body.staffs = body.staffs.map((staff) => staff.id);
      }

      if (body.primaryDiagnosis) {
        body.diagnosis = body.primaryDiagnosis.map((d) => d.key);
        delete body.primaryDiagnosis;
      }

      if (body.secondaryDiagnosis) {
        body.secondary_diagnosis = body.secondaryDiagnosis.map((d) => d.id);
        delete body.secondaryDiagnosis;
      }

      if (body.clinic) {
        body.company = [body.clinic];
        delete body.clinic;
      }

      if (body.labels) {
        body.labels = body.labels.map((label) => label.id);
      }

      if (body.hin_number) {
        body.hin = {
          hin_number: values.hin_number.split(' ').join(''),
        }
      } else if (body.hin_number === '') {
        body.hin = null;
      }
      delete body.hin_number;

      if (body.birthDate) {
        body.birthDate = formatBirthDateForPost(body.birthDate);
      }

      if (body.pharmacy) {
        body.extension = [{
          url: 'https://ca.takecareapi.com/fhir/StructureDefinition/patient-preferred-pharmacy',
          valueId: values.pharmacy,
        }];
        delete body.pharmacy;
      }

      if (body.identifier) {
        body.identifier = body.identifier.map((id) => ({
          value: id.value,
          system: id.system,
        }));
      }

      updateAccount({ id: uuid, body }).unwrap().then(() => {
        informations.setSubmitting(false);
      }).catch((error) => {
        informations.setSubmitting(false);
        // Assuming the server returns error details in a predictable format
        if (error.status === 400 && error.data) {
          const errors = error.data;
          informations.setErrors(errors);
        }
      });
    },
  });

  const handleAddIdentifier = (identifier) => {
    if (editIdentifierIndex !== null) {
      const updatedIdentifiers = informations.values.identifier.slice();
      updatedIdentifiers[editIdentifierIndex] = identifier;
      informations.setFieldValue('identifier', updatedIdentifiers);
      setEditIdentifierIndex(null);
    } else {
      informations.setFieldValue('identifier', [...informations.values.identifier, identifier]);
    }
  };

  const handleEditIdentifier = (index) => {
    setEditIdentifierIndex(index);
    setIdentifierModalOpen(true);
    handleMenuClose();
  };

  const handleRemoveIdentifier = (index) => {
    const updatedIdentifiers = informations.values.identifier.slice();
    updatedIdentifiers.splice(index, 1);
    informations.setFieldValue('identifier', updatedIdentifiers);
    handleMenuClose();
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  return (
    <>
      <form onSubmit={informations.handleSubmit}>
        <Typography variant="h5">
          {t('Patient Information')}
        </Typography>
        <Row>
          <FormTextField formik={informations} name="firstName" required label={t('First Name')} />
          <FormTextField formik={informations} name="lastName" required label={t('Last Name')} />
        </Row>
        <Row>
          <FormTextField formik={informations} name="email" label={t('Email')} />
          <PhoneNumber formik={informations} name="phoneNumber" />
        </Row>
        <Row>
          <ZipCode formik={informations} sx={{ mx: 1 }} />
          <BirthDate formik={informations} />
        </Row>
        <Row>
          <Language formik={informations} />
          <Sex formik={informations} />
        </Row>
        <Row>
          <EthnicGroup formik={informations} />
          <Hin formik={informations} />
        </Row>
        {access === 'PT' && (
          <>
            <Row>
              <Clinic formik={informations} />
              <Labels formik={informations} />
            </Row>
            <MultiSelect
              formik={informations}
              type="diagnosis"
              level="primary"
              label={t('Primary Diagnosis')}
              name="primaryDiagnosis"
            />
            {configs.secondary_diagnosis && (
              <MultiSelect
                formik={informations}
                type="diagnosis"
                level="secondary"
                name="secondaryDiagnosis"
                label={t('Secondary Diagnosis')}
              />
            )}
            <MultiSelect formik={informations} type="staffs" label={t('Assigned HCP')} name="staffs" />
          </>
        )}
        <Telecom informations={informations} />
        <FormControl sx={{ mx: 1, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel>{t('Identifiers')}</FormLabel>
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={() => setIdentifierModalOpen(true)}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <Row>
            {informations.values.identifier.map((id, index) => (
              <Box
                key={index}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  display: 'flex',
                  flexDirection: 'row',
                  mx: 1,
                  borderRadius: 3,
                  p: 1,
                }}
              >
                <Box sx={{ pl: 1, width: '80%' }} display="flex" flexDirection="column">
                  <Typography>
                    <strong>{`${t('Value')}: ${id.value}`}</strong>
                  </Typography>
                  <Typography>{`${t('System')}: ${id.system}`}</Typography>
                </Box>
                <Box sx={{ ml: 'auto', alignSelf: 'flex-start' }}>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMenuOpen(event, index)}
                  >
                    <MoreVertIcon sx={{ transform: 'scale(0.8)' }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && menuIndex === index}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={() => handleEditIdentifier(index)}>
                      {t('Edit')}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: (theme) => theme.palette.error.main }}
                      onClick={() => handleRemoveIdentifier(index)}
                    >
                      {t('Remove')}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            ))}
          </Row>
        </FormControl>
        <Row>
          {access === 'PT' && (
            <Box sx={{ mx: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel>{t('Additional Information')}</FormLabel>
              </Box>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  display: 'flex',
                  flexDirection: 'row',
                  mt: 1,
                  borderRadius: 3,
                  p: 1,
                }}
              >
                <Box sx={{ pl: 1, width: '100%' }} display="flex" flexDirection="column">
                  <Typography>
                    <strong>{data.name || ''}</strong>
                  </Typography>
                  <Typography>
                    {`${t('Registered since')}: ${moment(data.createdDate).format('LLL')}`}
                  </Typography>
                  <Typography>
                    {`${t('Last active on')}: ${moment(data.lastActive).format('LLL')}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Pharmacy formik={informations} />
        </Row>
        <Box sx={{ justifyContent: 'flex-end', display: 'flex', gap: 1 }}>
          {access === 'PT' && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
              disabled={informations.isSubmitting}
            >
              {t('Archive')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => informations.resetForm()}
            disabled={!informations.dirty || informations.isSubmitting}
          >
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={informations.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
      <ArchiveModal
        open={open}
        firstName={informations.values.firstName}
        handleClose={() => setOpen(false)}
        uuid={uuid}
      />
      <AddIdentifierDialog
        open={identifierModalOpen}
        handleClose={() => {
          setIdentifierModalOpen(false);
          setEditIdentifierIndex(null);
        }}
        handleSave={handleAddIdentifier}
        initialValues={
          editIdentifierIndex !== null
            ? informations.values.identifier[editIdentifierIndex]
            : null
        }
      />
    </>
  );
};

export default BasicInfoTab;
