import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  MenuItem,
  IconButton,
  TextField,
  Chip,
  Stack,
  Typography,
  InputAdornment,
  FormControl
} from '@mui/material';
import { useFormikContext, FieldArray } from 'formik';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import FormikAutocomplete from '../../../form-inputs/FormikAutocomplete';
import FormikSelect from '../../../form-inputs/FormikSelect';
import FormikTextField from '../../../form-inputs/FormikTextField';
import { dayOfWeekList, unitsOfTime, whenOptions } from './utils';

const Timing = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange } = useFormikContext();
  const [showTimeInput, setShowTimeInput] = useState(false);
  const [timeInput, setTimeInput] = useState('');
  const timeInputRef = useRef(null); // Ref to manage focus

  const dayOptions = Object.keys(dayOfWeekList).map(key => ({
    id: key,
    label: dayOfWeekList[key],
  }));

  const whenSelectOptions = Object.keys(whenOptions).map(key => ({
    id: key,
    label: whenOptions[key],
  }));

  const handleAddTime = (arrayHelpers) => {
    if (timeInput) {
      arrayHelpers.push(timeInput);
      setTimeInput(''); // Reset the input field
      setShowTimeInput(false); // Hide the input after adding
    }
  };

  const handleKeyPress = (e, arrayHelpers) => {
    if (e.key === 'Enter') {
      handleAddTime(arrayHelpers);
    }
  };

  const handleBlur = (arrayHelpers) => {
    handleAddTime(arrayHelpers);
  };

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid",
        borderColor: "gray.300",
        borderRadius: 2,
        boxShadow: 1,
        mb: 2,
      }}
    >
      <Box sx={{ pb: 2 }}>
        <FormLabel>{t('Timing')}</FormLabel>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)', // Two-column layout
          gap: 2,
        }}
      >
        <FormikTextField
          required
          name="timing.frequency"
          label={t('Frequency')}
          type="number"
        />
        <FormikTextField
          name="timing.frequencyMax"
          label={t('Frequency Max')}
          type="number"
        />
        <FormikTextField
          required
          name="timing.period"
          label={t('Period')}
          type="number"
        />
        <FormikSelect
          name="timing.periodUnit"
          label={t('Period Unit')}
        >
          {Object.entries(unitsOfTime).map(([key, val]) => (
            <MenuItem key={key} value={key}>
              {val}
            </MenuItem>
          ))}
        </FormikSelect>
        <FormikAutocomplete
          name="timing.when"
          label={t('When')}
          options={whenSelectOptions}
        />
        <FormikTextField
          name="timing.offset"
          label={t('Offset in minutes')}
          type="number"
        />
        <FormikTextField
          name="timing.duration"
          label={t('Duration')}
          type="number"
        />
        <FormikSelect
          name="timing.durationUnit"
          label={t('Duration Unit')}
        >
          {Object.entries(unitsOfTime).map(([key, val]) => (
            <MenuItem key={key} value={key}>
              {val}
            </MenuItem>
          ))}
        </FormikSelect>
        <FormikTextField
          name="timing.count"
          label={t('Count')}
          type="number"
        />
        <FormikTextField
          name="timing.countMax"
          label={t('Count Max')}
          type="number"
        />
        <FormControl variant="outlined" fullWidth>
          <FormLabel
            sx={{
              position: 'absolute',
              top: '-13px',
              left: '7px',
              backgroundColor: 'white',
              px: 1,
              fontSize: '0.75rem',
              color: 'text.secondary',
            }}
          >
            {t('Time of Day')}
            <IconButton
              onClick={() => {
                setShowTimeInput(true);
                setTimeout(() => timeInputRef.current?.focus(), 0); // Focus input after rendering
              }}
              color="primary"
              sx={{
                ml: 1,  // Aligns plus button beside the label
                p: 0.5, // Reduces padding for a smaller button
                fontSize: 'small', // Adjusts icon size
              }}
            >
              <AddIcon fontSize="small" /> {/* Set the icon size to small */}
            </IconButton>
          </FormLabel>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              p: '11px',
            }}
          >
            <FieldArray
              name="timing.timeOfDay"
              render={(arrayHelpers) => (
                <Box>
                  <Stack direction="row" spacing={1}>
                    {values.timing.timeOfDay && values.timing.timeOfDay.length > 0 ? (
                      values.timing.timeOfDay.map((time, index) => (
                        <Chip
                          key={index}
                          label={time}
                          onDelete={() => arrayHelpers.remove(index)}
                          sx={{ mb: 1 }}
                        />
                      ))
                    ) : (
                      !showTimeInput && (
                        <Box
                          sx={{
                            p: '6.5px',
                            color: 'grey',
                            cursor: 'pointer', // Makes the placeholder look clickable
                          }}
                          onClick={() => {
                            setShowTimeInput(true);
                            setTimeout(() => timeInputRef.current?.focus(), 0); // Focus input after rendering
                          }}
                        >
                          {t('Enter time (e.g., 08:30)')}
                        </Box>
                      )
                    )}
                  </Stack>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {showTimeInput ? (
                      <TextField
                        label={t('Add Time of Day')}
                        type="time"
                        value={timeInput}
                        onChange={(e) => setTimeInput(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyPress={(e) => handleKeyPress(e, arrayHelpers)}
                        onBlur={() => handleBlur(arrayHelpers)}
                        inputRef={timeInputRef} // Attach ref to input
                        sx={{ flexGrow: 1, mr: 2, mt: 2 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleAddTime(arrayHelpers)}
                                color="primary"
                              >
                                <CheckIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <Box sx={{ flexGrow: 1 }} />
                    )}
                  </Box>
                </Box>
              )}
            />
          </Box>
        </FormControl>
        <FormikAutocomplete
          name="timing.dayOfWeek"
          label={t('Day of Week')}
          options={dayOptions}
        />
        <FormControlLabel
          label={t('As needed')}
          sx={{ ml: 0.5 }}
          name="asNeeded"
          onChange={handleChange}
          control={<Checkbox checked={values.asNeeded} />}
        />

      </Box>
    </Box>
  );
};

export default Timing;
